<template>
  <div class="roleManagement" v-if="hasResource('role_management')">
    <div class="search-form">
      <el-button
        v-if="hasResource('admin_role_add')"
        type="primary"
        @click="addCustomer"
        class="black-btn"
      >新增角色</el-button>
      <el-form :inline="true">
        <el-form-item label="角色名称" class>
          <el-input v-model="role_name" placeholder="输入名称检索" clearable />
        </el-form-item>
        <el-form-item class="mg-l-60">
          <span
            class="handle-size cursor-pointer fw-bold search-button"
            @click="handleQuery"
            v-loading="isQueryLoading"
          >查询</span>
          <span
            class="mg-l-14 handle-size cursor-pointer fw-bold search-button"
            @click="resetQuery"
          >清空</span>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="roleData"
      style="width: 100%"
      header-cell-class-name="table-head"
      cell-class-name="table-cell"
      class="data-table"
      v-loading="isTableLoading"
      :row-class-name="tableRowClassName"
    >
      <el-table-column
        prop="role_name"
        label="角色名称"
        align="left"
        width="180"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="user_count"
        label="角色人数"
        align="left"
        width="110"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column label="操作" align="left" class-name="small-padding fixed-width">
        <template slot="header">
          <div style="padding-left:22px !important;">操作</div>
        </template>
        <template slot-scope="scope">
          <div class="icon-btn">
            <el-button
              class="edit-color"
              size="mini"
              type="text"
              @click="updateCustomer(scope.row)"
              v-if="hasResource('admin_role_edit')"
            >
              <i class="iconfont">&#xe677;</i>编辑
            </el-button>
            <el-button
              class="edit-color"
              size="mini"
              type="text"
              @click="handleStop(scope.row.role_id)"
              v-if="hasResource('admin_role_delete')"
            >
              <i class="iconfont">&#xe678;</i>删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="ta-r mg-t-18">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10,20,50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        v-if="total!=0"
      ></el-pagination>
    </div>

    <!-- 确认停用弹框 -->
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleStop"
      width="458px"
      class="pop-dialog"
      :modal-append-to-body="false"
    >
      <span class="fw-500 fz-18">是否确认删除该角色？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmStop" class="confirm-button">确 定</el-button>
        <el-button @click="dialogVisibleStop = false" class="cancel-button">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../../api/role";
export default {
  mixins: [],
  data() {
    return {
      roleData: [],
      currentPage: 1,
      pagesize: 10,
      total: 0,
      role_name: "",
      email: "",
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 新增弹框
      dialogVisibleAdd: false,
      form: {
        username: undefined,
        email: undefined,
        channel_id: undefined,
        status: undefined,
      },
      isQueryLoading: false,
      isTableLoading: false,
      isDialogAddLoading: false,
      // 修改弹框
      dialogVisibleUpdate: false,
      dialogVisibleStop: false,
      updateCustomerId: "",
      ids: [],
      preview: {
        username: undefined,
        email: undefined,
        channel: undefined,
        status: undefined,
      },
      stopRowId: 0,
      header: {
        url: "roleManagement",
      },
      recordDatd: {},
      parameters: false,
    };
  },
  methods: {
    async init() {
      if (this.getLocalCurrentPage("needUpdate")) {
        await this.updateFilterParams();
        await this.getRoleList();
        this.recordCurrentPage("needUpdate", false);
      } else {
        await this.getRoleList();
      }
    },
    getRoleList() {
      let params = {
        limit: this.parameters ? this.recordDatd.limit : this.pagesize,
        page: this.parameters ? this.recordDatd.page : this.currentPage,
        filter: {
          role_name: this.parameters ? this.recordDatd.name : this.role_name,
        },
      };
      this.isTableLoading = true;
      api.adminRoleList(params).then((res) => {
        if (res.data.code === 10200) {
          this.roleData = res.data.data.items;
          this.total = res.data.data.total;
          if (this.roleData.length <= 0 && this.currentPage > 1) {
            this.currentPage = 1;
            this.getRoleList();
          }
        }
        this.isTableLoading = false;
        this.isQueryLoading = false;
      });
    },
    updateFilterParams() {
      this.recordDatd = this.getLocalCurrentPage("roleList0415");
      let params = this.recordDatd;
      if (params) {
        this.parameters = true;
        this.pagesize = params ? params.limit : this.pagesize;
        this.currentPage = params ? params.page : this.currentPage;
        this.role_name = params ? params.name : this.role_name;
        this.getRoleList();
      }
    },
    // 预览重置
    reset() {
      this.preview = {};
    },
    // 搜索
    handleQuery() {
      this.isQueryLoading = true;
      this.currentPage = 1;
      this.getRoleList();
    },
    // 重置
    resetQuery() {
      this.role_name = "";
      this.getRoleList();
    },
    //新增用户
    addCustomer() {
      this.$router.push({
        path: "/role",
      });
    },
    // 确认新增用户
    CustomerCreate() {
      let isValid = this.createCustomerValid();
      if (!isValid) {
        return false;
      }
      this.encryptPassword();
      let data = {
        username: this.form.username,
        email: this.form.email,
        channel_id: this.form.channel_id,
        password: this.form.passwordEncrypt,
      };
      this.isDialogAddLoading = true;
      api.customerAdd(data).then((res) => {
        if (res.data.code === 10200) {
          this.getRoleList();
        } else {
          this.$$error(res.data.message);
        }
        this.dialogVisibleAdd = false;
        this.isDialogAddLoading = false;
      });
    },
    // 编辑角色
    updateCustomer(row) {
      this.$router.push({
        path: "/role",
        query: {
          role_id: row.role_id,
        },
      });
      const params = {
        limit: this.pagesize,
        page: this.currentPage,
        name: this.role_name,
      };
      this.recordCurrentPage("roleList0415", params);
    },
    // 表格中停用用户
    handleStop(row) {
      this.dialogVisibleStop = true;
      this.stopRowId = row;
    },
    //确认停用
    confirmStop() {
      let user_id = this.stopRowId;
      this.dialogVisibleStop = false;
      api.deleteAdminRole(user_id).then((res) => {
        if (res.data.code === 10200) {
          this.getRoleList();
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    handleStatusChange() {},
    handleResetPwd() {},
    handleSizeChange(val) {
      this.parameters = false;
      this.pagesize = val;
      this.getRoleList();
    },
    handleCurrentChange(val) {
      this.parameters = false;
      this.currentPage = val;
      this.getRoleList();
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status === 0) {
        return "stop-row";
      }
      return "";
    },
  },
  mounted() {
    this.msgBus.$on("roleList", () => {
      this.recordCurrentPage("needUpdate", true);
    });
    this.init();
  },
};
</script>
<style lang="less">
@import "scss/roleManagement.less";
</style>